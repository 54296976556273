<template>
  <div>
    <div class="detail-title">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>运营和营销</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/promotion' }"
          >组合购
        </el-breadcrumb-item>
        <el-breadcrumb-item>新建活动</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="detail-info">
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        label-width="120px"
        size="mini"
      >
        <el-form-item label="名称：" label-position="left" prop="name">
          <el-input v-model="form.name" style="width: 200px"></el-input>
        </el-form-item>

        <el-form-item label="封面图片：" label-position="left" prop="pic">
          <select-images
            :image="image"
            @onDelete="onDelete"
            @onSelect="onSelectImage"
          ></select-images>
        </el-form-item>

        <el-form-item label="分享海报：" label-position="left" prop="sharePic">
          <select-images
            :image="sharePic"
            @onDelete="onDelete"
            @onSelect="uploadSuccessSharePic"
          ></select-images>
        </el-form-item>

        <el-form-item label="选择课程：" label-position="left" prop="courseIds">
          <select-product @onSelect="onSelect"></select-product>
        </el-form-item>

        <el-form-item label="更新设置：" label-position="left">
          <el-descriptions
            v-for="item in form.selectProducts"
            :key="item.id"
            :column="2"
            border
            class="margin-top"
            direction="vertical"
            size="mini"
            style="margin-top: 10px"
          >
            <el-descriptions-item :span="1">
              <template slot="label"> 课程名称</template>
              <div style="width: 300px">
                {{ item.name }}
              </div>
            </el-descriptions-item>
            <el-descriptions-item :span="1">
              <template slot="label"> 更新时间</template>
              <div style="width: 300px">
                <el-date-picker
                  v-model="item.start_day"
                  placeholder="选择日期"
                  type="date"
                  value-format="yyyy-MM-dd"
                >
                </el-date-picker>
              </div>
            </el-descriptions-item>
          </el-descriptions>
        </el-form-item>

        <el-form-item label="详情：" label-position="left" prop="content">
          <editor :content="form.content" @onChange="onChange"></editor>
        </el-form-item>

        <!--                <el-form-item label="类型：" label-position="left" class="input" >-->
        <!--                    <el-select style="width: 200px" v-model="form.type" placeholder="请选择" @change="selectType">-->
        <!--                        <el-option-->
        <!--                                v-for="item in options"-->
        <!--                                :key="item.value"-->
        <!--                                :label="item.label"-->
        <!--                                :value="item.value">-->
        <!--                        </el-option>-->
        <!--                    </el-select>-->
        <!--                </el-form-item>-->
        <el-form-item
          class="input"
          label="活动时间："
          label-position="left"
          prop="startTime"
        >
          <el-date-picker
            v-model="value2"
            :picker-options="pickerOptions"
            align="right"
            end-placeholder="结束日期"
            range-separator="至"
            size="small"
            start-placeholder="开始日期"
            type="datetimerange"
            unlink-panels
            value-format="yyyy-MM-dd HH:mm:ss"
            @change="changeDateTime"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item
          class="input"
          label="价格："
          label-position="left"
          prop="amount"
        >
          <el-input v-model="form.amount" style="width: 200px"></el-input>
          元
        </el-form-item>

        <el-form-item label="参与人数：" label-position="left">
          <el-input v-model="form.restrict" style="width: 200px"></el-input>
          （0为无限制,团购活动为几人起拼团成功）
        </el-form-item>

        <el-form-item
          class="input"
          label="需要收货地址："
          label-position="left"
        >
          <el-switch
            v-model="form.needAddress"
            :active-value="1"
            :inactive-value="0"
            @change="setNeedAddress"
          >
          </el-switch>
          <span style="margin-left: 5px; font-size: 12px">{{
            form.needAddress ? "需要" : "不需要"
          }}</span>
        </el-form-item>

        <el-form-item class="input" label="需要邮箱：" label-position="left">
          <el-switch
            v-model="form.needEmail"
            :active-value="1"
            :inactive-value="0"
            @change="setNeedEmail"
          >
          </el-switch>
          <span style="margin-left: 5px; font-size: 12px">{{
            form.needEmail ? "需要" : "不需要"
          }}</span>
        </el-form-item>

        <el-form-item
          v-if="form.needEmail"
          label="附件："
          label-position="left"
        >
          <el-button size="mini" type="primary" @click="addFile"
            >选择附件
          </el-button>
          <div v-for="(item, index) in fileList" :key="index" class="flex">
            <div class="margin-r-sm">{{ item.name }}</div>
            <div class="margin-r-sm">{{ unitConversion(item.size) }}</div>
            <div class="margin-r-sm">{{ item.ext }}</div>
            <div class="margin-r-sm">
              <el-button type="text" @click="delFile(index)">删除</el-button>
            </div>
          </div>
        </el-form-item>

        <el-form-item class="input" label="状态：" label-position="left">
          <el-switch v-model="form.status" @change="setStatus"></el-switch>
          <span style="margin-left: 5px; font-size: 12px">{{ statusTxt }}</span>
        </el-form-item>

        <el-form-item>
          <el-button
            :loading="loading"
            size="small"
            type="primary"
            @click="onSubmit"
            >保存
          </el-button>
          <router-link class="router-link" to="/goods">
            <el-button size="small" style="margin-left: 10px">取消</el-button>
          </router-link>
        </el-form-item>
      </el-form>
    </div>
    <el-dialog :visible.sync="file" append-to-body title="附件" width="60%">
      <div class="margin-b-sm" style="padding: 10px">
        <el-input
          v-model="searchData.name"
          class="margin-r-sm"
          placeholder="附件名称"
          style="width: 120px"
        />
        <el-button size="mini" type="primary" @click="search">搜索</el-button>
      </div>
      <div v-infinite-scroll="load" class="table">
        <el-table
          ref="multipleTable"
          v-loading="fileLoading"
          :data="tableData"
          :header-cell-style="{ padding: 0 }"
          style="width: 100%; background: #fff"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55" />
          <el-table-column align="center" label="ID" property="id" width="80" />
          <el-table-column align="center" label="附件名称" property="name" />
          <el-table-column align="center" label="大小" property="size">
            <template slot-scope="scope">
              <span>{{ unitConversion(scope.row.size) }}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="扩展名" property="ext" />
          <el-table-column align="center" label="上传者" property="user_name" />
          <el-table-column
            align="center"
            label="创建时间"
            property="created_at"
          />
          <el-table-column
            align="center"
            label="更新时间"
            property="updated_at"
          />
        </el-table>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="file = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Editor from "../../../components/editor";
import SelectProduct from "../../../components/selectProduct";
import SelectImages from "../../../components/selectImages/index";

export default {
  name: "template-add",
  components: { SelectImages, SelectProduct, Editor },
  data() {
    return {
      tableData: [],
      file: false,
      fileLoading: false,
      dialog: true,
      searchData: {},
      page: 1,
      pageSize: 10,
      fileList: [],
      imageUrl: config.imageUrl,
      statusTxt: "上架",
      loading: false,
      uploadPath: "promotion",
      image: "",
      sharePic: "",

      form: {
        name: "",
        type: 1,
        status: true,
        needAddress: 1,
        needEmail: 1,
        pic: "",
        sharePic: "",
        amount: 0,
        content: "",
        restrict: 0,
        startTime: "",
        endTime: "",
        courseIds: [],
        selectProducts: [],
        courseType: 1,
        dataFiles: [],
      },
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 3600 * 1000 * 24;
        },
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      value2: "",
      options: [
        {
          value: 1,
          label: "组合购",
        },
      ],
      rules: {
        name: [{ required: true, message: "请输入课程名称", trigger: "blur" }],
        pic: [
          {
            type: "string",
            required: true,
            message: "请选择图片封面",
            trigger: "change",
          },
        ],
        sharePic: [
          {
            type: "string",
            required: true,
            message: "请选择分享海报图",
            trigger: "change",
          },
        ],
        content: [
          {
            type: "string",
            required: true,
            message: "请输入课程内容",
            trigger: "blur",
          },
        ],
        amount: [{ required: true, message: "请输入价格", trigger: "blur" }],
      },
    };
  },
  methods: {
    ...mapActions("marketing", ["addPromotion"]),
    ...mapActions("mailbox", ["attachmentFileList"]),

    delFile(index) {
      this.fileList.splice(index, 1);
      this.form.dataFiles.splice(index, 1);
    },
    removeDuplicatesBySameId(arr) {
      let result = arr.reduce(function (unique, obj) {
        // 检查unique数组中是否已存在具有相同id的对象
        let exists = unique.some(function (item) {
          return item.id === obj.id;
        });

        // 如果不存在具有相同id的对象，则将当前对象添加到unique数组中
        if (!exists) {
          unique.push(obj);
        }

        return unique;
      }, []);

      return result;
    },
    handleSelectionChange(value) {
      this.fileList.push(...value);
      this.fileList = this.removeDuplicatesBySameId(this.fileList);
      this.form.dataFiles.push(...this.fileList.map((v) => v.id));
      this.form.dataFiles = Array.from(new Set(this.form.dataFiles));
    },
    unitConversion(size) {
      return (size / 1024 / 1024).toFixed(2) + "M";
    },
    async addFile() {
      this.dialog = true;
      let _this = this;
      _this.page = 1;
      this.tableData = [];
      this.searchData = {};
      await _this.getFileList();
      const rows = _this.getMatchingValues(this.tableData, this.form.dataFiles);
      _this.$nextTick(() => {
        rows.forEach((row) => {
          _this.$refs.multipleTable.toggleRowSelection(row);
        });
      });
    },
    getMatchingValues(array1, array2) {
      const result = [];
      for (let i = 0; i < array1.length; i++) {
        const item1 = array1[i];
        for (let j = 0; j < array2.length; j++) {
          const item2 = array2[j];
          if (item1.id === item2) {
            result.push(item1);
            break;
          }
        }
      }
      return result;
    },
    async getFileList() {
      try {
        this.file = true;
        this.fileLoading = true;
        let form = {
          page: this.page,
          pageSize: this.pageSize,
          ...this.searchData,
        };
        const { data } = await this.attachmentFileList(form);
        this.tableData = this.tableData.concat(data.list);
        if (this.page * this.pageSize > data.total) {
          this.dialog = false;
        }
      } finally {
        this.fileLoading = false;
      }
    },
    search() {
      this.dialog = true;
      this.page = 1;
      this.tableData = [];
      this.getFileList();
    },
    async load() {
      if (this.dialog) {
        let _this = this;
        ++_this.page;
        await _this.getFileList();
        const rows = _this.getMatchingValues(
          this.tableData,
          this.form.dataFiles
        );
        _this.$nextTick(() => {
          rows.forEach((row) => {
            _this.$refs.multipleTable.toggleRowSelection(row);
          });
        });
      }
    },
    uploadSuccess(path) {
      this.form.pic = path;
    },
    uploadSuccessSharePic(image) {
      this.sharePic = this.imageUrl + image.path;
      this.form.sharePic = image.path;
    },
    changeDateTime(value) {
      console.log(value !== null);
      if (value !== null) {
        this.form.startTime = value[0];
        this.form.endTime = value[1];
      } else {
        this.form.startTime = "";
        this.form.endTime = "";
      }
    },
    onChange(content) {
      this.form.content = content;
    },
    onSelect(product) {
      console.log("onSelectonSelectonSelect");
      console.log(product);
      this.form.selectProducts = product[0].data;
      this.form.courseIds = product[0].ids;
      switch (product[0].type) {
        case "course":
          this.form.courseType = 1;
          break;
        case "checkCourse":
          this.form.courseType = 2;
          break;
      }
    },
    onSelectImage(image) {
      this.image = imageUrl + image.path;
      this.form.pic = image.path;
    },
    onDelete() {
      this.image = "";
    },
    async onSubmit() {
      let _this = this;

      console.log(this.form);
      console.log(this.form.selectProducts);

      if (!this.validateForm("form")) {
        this.$message.error("请填写必填选项！");
        return false;
      }
      this.loading = true;

      this.addPromotion(this.form)
        .then((res) => {
          if (res.ret == 0) {
            this.$message.success("新建活动成功！");
            this.$router.push("/promotion");
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    setNeedAddress(v) {
      this.form.needAddress = v;
    },
    setNeedEmail(v) {
      this.form.needEmail = v;
    },
    setStatus(status) {
      console.log(status);
      if (this.form.status) {
        this.statusTxt = "上架";
      } else {
        this.statusTxt = "下架";
      }
      this.form.status = status;
    },
    selectType(val) {
      this.form.type = val;
    },
    validateForm(formName) {
      let validate = false;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          validate = true;
          return true;
        } else {
          validate = false;
          return false;
        }
      });
      return validate;
    },
  },
};
</script>

<style scoped>
.main {
  padding: 10px;
}

.title {
  width: 4em;
}

.recipients {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.flex {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 10px;
}

.file-list {
  padding: 5px 0;
  //border-bottom: 1px solid #eee;
}

.item {
  padding: 10px 0;
  border-bottom: 1px solid #eee;
  justify-content: start;
}

.file {
  cursor: pointer;
}

.table {
  height: 50vh;
  overflow: auto;
}

.table::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.table::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}

/* 滚动条滑块 */
.table::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background: #bbb;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.25);
}
</style>